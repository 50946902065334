import React from 'react';
import SEO from "../common/SEO";

import BreadcrumbOne from "../elements/breadcrumb/BreadcrumbOne";
import SectionTitle from "../elements/sectionTitle/SectionTitle";
import ContactOne from "../elements/contact/ContactOne";
import HeaderTwo from '../common/header/HeaderTwo';
import BlogGridView from '../components/blog/BlogGridView'
import { useStaticQuery, graphql } from 'gatsby';






const Work = ({data}) => {
    return (
        <>
            <SEO title="Work | Edgardo Medina" />
            <HeaderTwo/>
          <BlogGridView
          data={data}
          />
        </>
    )
}
export default Work;